<!-- 轨迹展示 -->
<template>
    <div class="pathShow">
        <my-Titlebar  style="z-index: 999 !important;">
            <template slot="TitlebarLeft">
                <div class="pathShow-titLeft">
                    <span>数据：</span>
                    <label><span style="font-size: 40px;font-family: 'georgia'">{{total}}</span><span> /条</span></label>
                </div>
            </template>
            <template slot="TitlebarRight">
                <div class="pathShow-titRight">
                    <el-date-picker v-model="TimeData" :disabled="clickData.T_sn===''?true:false" type="datetimerange" @change="TimeChang"
                         range-separator="至" :clearable="false"
                         style="width: 360px;"
                         @focus="showTimeModes = false"
                        value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <div class="pathShow-titRight-model" v-if="showTimeModes" data-aos="fade-left" data-aos-delay="0">
                        <div class="pathShow-titRight-model-title">
                            <div class="pathShow-titRight-model-title-left">
                                <i class="el-icon-info" style="color: red;margin-right: 10px;"></i>
                                <span style="font-size: 12px;">点击相应时间段查询轨迹</span>
                            </div>
                            <div class="pathShow-titRight-model-title-right" @click="showTimeModes = false">
                                <i class="el-icon-close" style="font-size: 20px;"></i>
                            </div>
                        </div>
                        <div class="pathShow-titRight-model-conter">
                            <div v-for="item,index in timeList" :key="index" @click="clickTimefn(item)" data-aos="zoom-in" :data-aos-delay="index*100">
                                <p>{{ item.T_Ut_start }}</p>
                                <p>{{ item.T_Ut_end }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </my-Titlebar>
        <div class="pathShow-mais">
            <div class="pathShow-conters-nav" data-aos="fade-right" data-aos-delay="0">
                <div class="pathShow-conters-nav-search" @click="searchDrawer = true">
                    <i class="el-icon-search"></i>
                </div>
                <div class="pathShow-conters-nav-top">
                    <div :class="actives==index?'nav-item-active':'pathShow-conters-nav-item'" v-for="item,index in list"
                    :key="index" @click="seriesDataFn(item,index)">
                        {{ item.T_name }}
                    </div>
                </div>
                <div class="pathShow-conters-nav-logo">
                    <img src="../../assets/img/bdMap.png" style="width: 100%;height: 100%; object-fit: contain;"/>
                </div>
            </div>
            <div class="mapsIt" data-aos="fade-left" data-aos-delay="0">
                <getmap :mapArr="mapArr"></getmap>
            </div>
        </div>
        <el-drawer :visible.sync="searchDrawer" direction="ttb"  style="z-index: 9999 !important;">
            <div style="display: flex;justify-content: center;">
                <el-input placeholder="请输入内容" v-model="initPomes.T_name" style="max-width: 600px;margin-right: 20px;">
                    <el-select v-model="initPomes.T_calssid" slot="prepend" placeholder="请选择" style="width: 110px;">
                        <el-option label="所有分类" value=""></el-option>
                        <el-option :label="item.T_name" :value="item.Id" v-for="item,index in selectData" :key="index"></el-option>
                    </el-select>
                </el-input>
                <el-button type="primary" @click="onSubmit">立即查询</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import getmap from "./maps.vue";
import { DevSenList } from "@/api/dataDisplay/chartShow"
import { DeviceTaskList,DataList } from "@/api/dataDisplay/mapShow"

import { ClassAll } from "@/api/ClassAll"
import { formatDate } from '@/utils/Times.js'

export default {
    components:{getmap},
    data() {
        return {
            timeList:[],//时间列表
            showTimeModes:false,//显示时间model
            searchDrawer:false,
            total:0,
            list:[],
            actives:null,//选中项
            drawer:false,
            selectData:[],
            initPomes:{
                T_name: '', 
                T_calssid: '', 
                T_MapShow: 1,
                page: 1,
                page_z: 666
            },
            TimeData:[],
            clickData:{//点击调取时间列表参数
                T_sn: '',
                Time_start:  '',
                Time_end:  '',
                page: 1,
                page_z: 99999,
                T_id:null
            },
            mapArr:[]
        }
    },
    mounted() {
        this.getdevsenListApi()
        this.classall()
        this.setTimefn()
    },
    methods:{
        setTimefn() { //获取当天0点与当前时间 
			const now = new Date();  
			const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0); 
			const min = midnight.getTime()//今天0点时间戳，
			const max = now.getTime(); //当前时间戳
			this.clickData.Time_start = formatDate(min)
			this.clickData.Time_end = formatDate(max)
			this.TimeData = [formatDate(min),formatDate(max)]
			this.$emit("pick", this.TimeData)
		}, 
        TimeChang(e) { //选择完时间确定按钮
            if(e){
                this.clickData.Time_start = e[0]
			    this.clickData.Time_end = e[1]
            }else{
                this.clickData.Time_start = ''
			    this.clickData.Time_end =  ''
            }
            this.getMapDataApi()
		},
        clickTimefn(item){
            this.clickData.Time_start = item.T_Ut_start
            this.clickData.Time_end = item.T_Ut_end
            this.TimeData = [item.T_Ut_start,item.T_Ut_end]
            this.$emit("pick", this.TimeData)

            this.getMapDataApi()
        },
        async getMapDataApi(){//点击时间获取数据
            const reslut = await DataList(this.clickData)
            if(reslut.data.Code==200){
                this.mapArr = reslut.data.Data.DeviceSensor_data || []
            }
        },
        async TaskListApi(){//获取时间列表
            const reslut = await DeviceTaskList(this.clickData)
            console.log('时间',reslut)
            if(reslut.data.Code==200){
                this.timeList = reslut.data.Data.Data || []
            }
        },
        
        onSubmit(){
            this.getdevsenListApi()
            this.actives = null
            this.searchDrawer = false
            this.showTimeModes = false
        },
        async classall(){//获取所有分类
            const reslut = await ClassAll({})
            if(reslut.data.Code==200){
                this.selectData = reslut.data.Data.Data || []
            }
        },
        //获取导航Api
        async getdevsenListApi(){
            const reslut = await DevSenList(this.initPomes)
            if(reslut.data.Code==200){
                this.total = reslut.data.Data.Num
                let arr = reslut.data.Data.DeviceSensor_lite || []
                this.list = arr
            }
        },
        //点击菜单按钮
        async seriesDataFn(item,index){
            console.log('点击',item)
            this.clickData.T_sn = item.T_sn
            this.clickData.T_id = item.T_id
            this.showTimeModes = true
            this.actives = index
            this.TaskListApi()
        },
                
        //搜索按钮
        clickSearchFun(){
            this.drawer = true
        }
    }
}
</script>
<style scoped>
::v-deep .el-drawer {
    background-color: #082c5f !important;
    border: 2px solid #23488f;
    box-shadow: 0 0 20px 10px rgba(35, 72, 143,0.5) inset;           
}

::v-deep .el-drawer__header {
    color: #5fc5ff;
    justify-content: center;
}

::v-deep .el-drawer__header>:first-child {
    flex: 1;
    font-size: 20px;
    font-weight: bold
}
::v-deep .el-input__inner {
    text-align: left !important;
}
::v-deep .el-date-editor .el-range-input {
    color: #5fc5ff;
    background: transparent;
}
::v-deep .el-date-editor .el-range-separator {
    color: #5fc5ff;
}
::v-deep .el-checkbox {
    color: #fff;
}
</style>
<style scoped>
::v-deep .el-input__inner {
    background-color: transparent !important;
    border: 1px solid #24509b;
    color: #5fc5ff;
    text-align: center;
}
::v-deep .el-form-item__label{
    color: #5fc5ff !important;

}
::v-deep .el-input__inner:focus {
    border: 1px solid #24509b;
}
</style>
<style lang="scss">
@import url('../../assets/scss/pathShow.scss');
</style>